<template>
  <div class="horizontal-padding">
    <page-not-found-content />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PageNotFoundContent from '@/components/PageNotFoundContent.vue';

export default defineComponent({
  components: {
    PageNotFoundContent,
  },
});
</script>
